import React, { useContext, useState } from "react";
import Fade from "@material-ui/core/Fade";
import * as yup from "yup";
import { Formik } from "formik";

import { CartApiCalls } from "api";
import {
  CartList,
  ErrorModal,
  Header,
  ShippingAddressOverview,
  SimpleButton,
  PoweredBy
} from "components";
import { CartItemsContext, NavigationContext } from "context";
import { CartScreensEnum } from "enums";
import themesEnum from "enums/themesEnum";
import {  values as storageValues } from 'storage';
import SamplesInfo from '../components/cartPopover/shippingAddress/samplesInfo';
import Summary from '../components/cartPopover/shippingAddress/summary';

const hederTitleByTheme = {
  default: 'Cart Overview',
  [themesEnum["steelcase-light"]]: 'Checkout',
};

const cartListTextByTheme = {
  default: 'COMPLETE SAMPLE ORDER',
  [themesEnum["steelcase-light"]]: 'Place Sample Order',
}

const validationSchema = (isProjectNameRequired) => (
  yup.object().shape({
    projectName: isProjectNameRequired
      ? yup.string().required("The project name is required")
      : yup.string().nullable(),
  })
);

const CartOverview = () => {
  const { onClose, onSetScreen, onSetScreenProps } = useContext(NavigationContext);
  const { onClearCart, isProjectNameRequired } = useContext(CartItemsContext);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [visible, setVisible] = useState(true);
  const theme = storageValues?.initObject?.theme || 'default';
  const shouldShowPrice = storageValues?.initObject?.showPricing;
  const orderButtonClassName = `mbw-button mbw-button__theme__${theme}`;

  const handlePlaceOrder = async ({ projectName, projectType }) => {
    setButtonLoading(true);

    const { isOk, data, message = null } = await CartApiCalls.placeOrder(projectName, projectType);

    if (isOk) {
      const emailResponse = await CartApiCalls.sendOrderEmail(data);
      const requestedBy = storageValues.extraMetadata.requestedBy;
      if (requestedBy != null){
        await CartApiCalls.sendOrderEmail({ ...data, order: {...data?.order, customer: {...data?.order?.customer, email: requestedBy }} });

      }
      if (!emailResponse.isOk) {
        setVisible(false);
        setButtonLoading(false);
        setShowErrorModal(true);
      } else {
        setVisible(false);
        setTimeout(() => {
          onClearCart();
          onSetScreen(CartScreensEnum.THANK_YOU);
          setButtonLoading(false);
          onSetScreenProps({ orderItems: data.items });
        }, 450);
      }
    } else {
      setButtonLoading(false);

      if (message) {
        return window['cart-widget']._MBWidgetDisplayErrorModal({ title: data, message });
      } else {
        setShowErrorModal(true);
      }
    }
  };

  const CartOverviewContentByTheme = {
    default: (
      <div className="mbw-step-container">
      <Fade in={visible} timeout={450}>
        <Formik
          initialValues={{ projectName: null }}
          validationSchema={validationSchema(isProjectNameRequired)}
          onSubmit={handlePlaceOrder}
        >
          {({ handleSubmit, errors, values }) => (
            <>
              <Header
                title={hederTitleByTheme[theme]}
                onClose={onClose}
              />
              <ShippingAddressOverview />
              <CartList
                small
                textButton={cartListTextByTheme[theme]}
                onClickButton={handleSubmit}
                buttonLoading={buttonLoading}
              />
            </>
          )}
        </Formik>
      </Fade>
      {showErrorModal && (
        <ErrorModal
          onCloseModal={() => setShowErrorModal(false)}
          message="Please try again or contact us if the error persists."
          title="Something went wrong."
        />
      )}
    </div>
    ),
    [themesEnum["steelcase-light"]]: (
      <div className="mbw-step-container">
      <Fade in={visible} timeout={450}>
        <Formik
          initialValues={{ projectName: null }}
          validationSchema={validationSchema(isProjectNameRequired)}
          onSubmit={handlePlaceOrder}
        >
          {({ handleSubmit, errors, values }) => (
            <>
              <Header
                title={hederTitleByTheme[theme]}
                subtitle="Step 2 of 2"
                onClose={onClose}
              />
              <SamplesInfo />
              {shouldShowPrice && (<Summary />)}
              <ShippingAddressOverview />
              <div className="mbw-footer">
                <div className={`mbw-footer__container mbw-footer__container__theme__${theme}`}>
                  <SimpleButton
                    className={orderButtonClassName}
                    onClick={buttonLoading ? null : handleSubmit}
                    text={cartListTextByTheme[theme]}
                    loading={buttonLoading}
                  />
                  <PoweredBy containerClassName='mbw-footer__powered-by' />
                </div>
              </div>
            </>
          )}
        </Formik>
      </Fade>
      {showErrorModal && (
        <ErrorModal
          onCloseModal={() => setShowErrorModal(false)}
          message="Please try again or contact us if the error persists."
          title="Something went wrong."
        />
      )}
    </div>
    ),
  };

  return CartOverviewContentByTheme[theme];
};

export default CartOverview;
