import React, { useContext } from "react";
import PlaceOutlinedIcon from "@material-ui/icons/PlaceOutlined";

import { values } from "storage";
import { CartItemsContext, NavigationContext } from "context";
import { CartScreensEnum } from "enums";
import { Input, PoweredBy, Select, SimpleButton } from "components";
import themesEnum from "enums/themesEnum";
import { projectTypes } from "utils";

const namingTextClassNameByTheme = {
  default: "mbw-small-text mbw-bold-text",
  [themesEnum["steelcase-light"]]: "mbw-small-text",
};

const Overview = () => {
  const { onSetScreen } = useContext(NavigationContext);
  const { isProjectNameRequired } = useContext(CartItemsContext);
  const theme = values?.initObject?.theme || "default";
  const orderButtonClassName = `mbw-button mbw-button__theme__${theme}`;

  const renderFormattedAddress = (renderInput = false) => {
    if (!values.address) {
      return <p className="mbw-small-text mbw-address">No address found.</p>;
    }

    const {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      zipCode,
      email,
      phoneNumber,
    } = values.address;

    const formattedAddressFormByTheme = {
      default: (
        <div className={`mbw-address mbw-address__theme__${theme}`}>
          <p className={namingTextClassNameByTheme[theme]}>
            {firstName} {lastName}
          </p>
          <p className="mbw-small-text">{address1}</p>
          {address2 && <p className="mbw-small-text">{address2}</p>}
          <p className="mbw-small-text">
            {city}, {state} {zipCode}
          </p>
          <p className="mbw-small-text">{email}</p>
          <p className="mbw-small-text">{phoneNumber}</p>
        </div>
      ),
      [themesEnum["steelcase-light"]]: (
        <div className={`mbw-address mbw-address__theme__${theme}`}>
          <p className={namingTextClassNameByTheme[theme]}>
            {firstName} {lastName}
          </p>
          <p className="mbw-small-text">{address1}</p>
          {address2 && <p className="mbw-small-text">{address2}</p>}
          <p className="mbw-small-text">
            {city}, {state} {zipCode}
          </p>
          <div className="flex-container flex-container__vertically__centered flex-container__horizontally__space-between">
            <div>
              <p className="mbw-small-text">{email}</p>
              <p className="mbw-small-text">{phoneNumber}</p>
            </div>
            {renderInput && (
              <div className="mbw-form__field__steelcase-light">
                <Input
                  name="projectName"
                  placeholder="Enter project name"
                  className="mbw-form__field"
                  fullWidth
                />
              </div>
            )}
          </div>
          <div className="mbw-form__field__steelcase-light">
            <Select
              className="mbw-form__select"
              name="projectType"
              options={projectTypes}
              label="Project Type"
              placeholder="Project Type"
            />
          </div>
        </div>
      ),
    };

    return formattedAddressFormByTheme[theme];
  };

  const overviewTitleByTheme = {
    default: (
      <div className="mbw-title">
        <PlaceOutlinedIcon className="mbw-title__icon" />
        <p className="mbw-title__text">Shipping To</p>
      </div>
    ),
    [themesEnum["steelcase-light"]]: (
      <div className="mbw-title">
        <p className="mbw-shipping-address-cart-info__text mbw-shipping-address-cart-info__text-container__theme__steelcase-light">
          Shipping
        </p>
      </div>
    ),
  };

  const projectNameInputByTheme = {
    default: (
      <>
        {renderFormattedAddress(false)}
        {isProjectNameRequired && (
          <div className="mbw-form">
            <Input
              name="projectName"
              placeholder="Enter project name"
              className="mbw-form__field"
              fullWidth
            />
            <Select
              className="mbw-form__select"
              name="projectType"
              options={projectTypes}
              onInputChange={() => {}}
              label="Project Type"
              placeholder="Project Type"
            />
          </div>
        )}
      </>
    ),
    [themesEnum["steelcase-light"]]: (
      <>{renderFormattedAddress(isProjectNameRequired)}</>
    ),
  };

  return (
    <div
      className={`mbw-shipping-address-overview mbw-shipping-address-overview__theme__${theme}`}
    >
      <div className={`mbw-header mbw-header__theme__${theme}`}>
        {overviewTitleByTheme[theme]}
        <p
          className={`mbw-small-text mbw-underline-text mbw-small-text__theme__${theme}`}
          onClick={() => onSetScreen(CartScreensEnum.EDIT_ADDRESS)}
        >
          Edit
        </p>
      </div>
      {projectNameInputByTheme[theme]}
    </div>
  );
};

export default Overview;
